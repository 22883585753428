.mainContainer {
  width: 248px;
  height: 100vh;
  background-color: #1B1B1B;
  /* border-top-right-radius: 20px; */
  /* border-bottom-right-radius: 20px; */
  position: relative;
}

.LeftSocialIcons {
  position: absolute;
  bottom: 50px;
  left: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-size: 29px;
  color: white !important;

}

.LeftSocialIcons a {
  cursor: pointer;
  color: white;
}

.LeftSocialIcons a:hover {
  cursor: pointer;
  scale: 1.1;
}

.innerContainer {
  padding-top: 50px;
}

.linkContainer {
  padding-left: 20px;
  margin-top: 50px;
}

a:hover {
  color: white !important;
}

.activeLink {
  display: block;
  background-color: #fbbf04;
  padding: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  border-top-left-radius: 10px;
  color: black !important;
  border-bottom-left-radius: 10px;
}

.inActiveLink {

  display: block;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  text-decoration: none;
}

.activeLinkHamburger {
  display: block;
  background-color: #fbbf04;
  padding: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  border-radius: 10px;
  color: black !important;
}

.inActiveLinkHamburger {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  color: white;
  text-decoration: none;
}

.imgBar {
  margin-right: 20px;
  font-size: 27px;
}


@media only screen and (max-width: 768px) {
  .mainContainer {
    display: none;
  }
}