@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-Regular.ttf");
}

body {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #141414 !important;
  font-family: "Inter" !important;
}

/* Firefox */
* {
  color: white;
  scrollbar-width: thin;
  scrollbar-color: #787878 #b82828;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #000000;
}

*::-webkit-scrollbar-thumb {
  background-color: #464545;
  border-radius: 10px;
}

/* ------------------ */
.mo-show-only {
  display: none;
}

.apexcharts-tooltip.apexcharts-theme-light {
  padding: 6px !important;
  width: 127px !important;
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid rgba(168, 255, 53, 0.1) !important;
  backdrop-filter: blur(5px) !important;
  border-radius: 10px !important;
}

.chart-tooltip-font-date {
  color: white;
  font-size: 15px;
}

.chart-tooltip-font {
  font-size: 20px;
  color: #fbbf04;
}

@media (max-width: 600px) {
  .mo-show-only {
    display: flex;
  }

  .desktop-show-only {
    display: none;
  }
}

@media (min-width: 600px) {
  .desktop-show-only {
    display: flex;
  }
}

/* support */

.support_btn_active {
  border-bottom: 1px solid #fbbf04 !important;
}

.allbtn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px;
  /* text-transform: capitalize; */
  line-height: 20px;
  border-radius: 0px !important;
  color: #ffffff !important;
  padding: 15px 6px !important;
}

.unReadbtn {
  font-family: "Inter";
  font-style: normal !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff !important;
  border-radius: 0px !important;
  opacity: 0.6;
  padding: 15px 6px !important;
}

/* Custom drondown menu of material */
.MuiMenu-paper {
  background: #161203 !important;
}

.MuiPopover-paper {
  background: #161203 !important;
  color: white !important;
}

.apexcharts-toolbar {
  display: none !important;
}
.apexcharts-bar-area {
  border-radius: 10px !important;
  fill: #FBBF04 !important;
 
}
.Toastify .Toastify__toast-body div{
  color: black !important;
}