/* background: "#252525",
borderRadius: "8px",
width: { md: "60px", xs: "42px" },
height: { md: "80px", xs: "55px" }, */

.inputStyle {
  background-color: #252525;
  border-radius: 8px;
  height: 80px;
  color: white !important;
  padding: 0px 24px;
  max-width: 160px !important;
  border: none;
}
.spantag {
  padding: 0px 5px;
  color:  #252525;
}

@media only screen and (max-width: 600px) {
  .inputStyle {
    background-color: #252525;
    border-radius: 8px;
    height: 55px;
    padding: 0px 20px;
    max-width: 60px !important;
    border: none;
  }
  .spantag {
    padding: 0;
    /* color: red; */
  }
}
